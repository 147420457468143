import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { Link, useLoaderData, useOutletContext } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import { Eye } from "lucide-react";
import React from "react";
import { useInView } from "react-intersection-observer";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from "react-virtualized";
import type { ListRowProps } from "react-virtualized";
import { ClientOnly } from "remix-utils/client-only";
import { showLatestUpdateBarAtom, useAtom } from "~/atoms";
import { CreatePost } from "~/components/create-post";
import { FocusCarousel } from "~/components/focus-carousel";
import { MediaCarousel } from "~/components/media-carousel";
import {
  DeletePostPopoverItem,
  EditPostPopoverItem,
  Post,
  PostAvailableStickers,
  PostBody,
  PostComment,
  PostContent,
  PostInfo,
  PostMenu,
  PostMenuPopover,
  PostMenuPopoverItem,
  PostSlapBox,
  PostStickers,
  PostUser,
  TipPostPopoverItem,
} from "~/components/post";
import PostModal from "~/components/post-modal";
import { RightSidebar } from "~/components/right-sidebar";
import { db, sql } from "~/db";
import { useCurrentUser } from "~/hooks/use-current-user";
import { useIsCollapse } from "~/hooks/use-is-collapse";
import type { PostSticker, Post as PostType } from "~/types";
import { cn } from "~/util/css";
import { getMetadata } from "~/util/metadata";
import {
  extractHashtags,
  extractMentions,
  stripBannedTags,
} from "~/util/parse-tags";
import { redirectFail, redirectOk } from "~/util/redirects";
import { imageUrlRegex, videoUrlRegex } from "~/util/sanitize";
import {
  getCurrentUserFromSession,
  getUserIdFromSession,
} from "~/util/sessions/login";

export function shouldRevalidate() {
  return true;
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return getMetadata({ image: `${data?.originUrl}/soulbound-social.webp` });
};

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const formContent = formData.get("content");
  const content = formContent?.toString();
  const userId = await getUserIdFromSession(request);
  const currentUser = await getCurrentUserFromSession(request);

  if (!userId) {
    return redirectFail("/lobby", "You must be logged in to post.");
  }
  let mediaUrls = formData.getAll("media");
  if (mediaUrls.length === 0) {
    return redirectFail(
      request.url,
      "You must upload at least one image or video.",
    );
  }

  mediaUrls = mediaUrls
    .filter(Boolean)
    .filter((url) => url.toString().trim() !== "");

  const hasImage = mediaUrls.some((url) => imageUrlRegex.test(url.toString()));
  const hasVideo = mediaUrls.some((url) => videoUrlRegex.test(url.toString()));
  const postType =
    hasImage && hasVideo ? "media" : hasImage ? "image" : "video";

  if (mediaUrls.length === 0) {
    return redirectFail(
      request.url,
      "Error uploading media. Please try again.",
    );
  }

  const hashtags = extractHashtags(content);
  const mentions = extractMentions(content);
  try {
    const postId = await db.transaction().execute(async (tx) => {
      let tags: any;
      let bannedTags: any;
      if (hashtags.length > 0) {
        const tagValues = hashtags.map((hashtag) => ({
          name: hashtag,
          count: 1,
          updated_at: sql`now()`,
        }));

        tags = await tx
          .insertInto("tags")
          .values(tagValues)
          .onConflict((oc) =>
            oc.column("name").doUpdateSet({
              count: sql`case when ${sql.raw(
                "tags.deleted_at",
              )} is null then ${sql.raw("tags.count")} + 1 else ${sql.raw(
                "tags.count",
              )} end`,
              updated_at: sql`now()`,
            }),
          )
          .returning(["id", "name", "deleted_at"])
          .execute();

        bannedTags = tags
          //@ts-ignore
          ?.filter((tag) => tag.deleted_at)
          //@ts-ignore
          .map((tag) => `#${tag.name}`);
      }

      let cleanContent = content ? content.toString().substring(0, 500) : "";
      if (bannedTags && bannedTags.length > 0) {
        cleanContent = stripBannedTags(cleanContent, bannedTags);
      }

      const newPost = await tx
        .insertInto("posts")
        .values({
          user_id: userId,
          content: cleanContent,
          post_type: postType,
          media_urls: mediaUrls,
        })
        .returning("id")
        .executeTakeFirst();

      if (!newPost) {
        throw new Error("Failed to create post.");
      }

      const dailyCreatePostQuest = await tx
        .selectFrom("quests")
        .select(["id", "max_spins"])
        .where("slug", "=", "daily/create_post")
        .executeTakeFirstOrThrow();

      const totalSpins = await tx
        .selectFrom("spins")
        .select(({ fn }) => [fn.count<number>("id").as("total_spins")])
        .where("user_id", "=", userId)
        .where("quest_id", "=", dailyCreatePostQuest.id)
        .where("created_at", ">=", sql`current_date`)
        .executeTakeFirst();

      const total_spins = totalSpins?.total_spins ?? 0;

      if (
        dailyCreatePostQuest.max_spins !== null &&
        total_spins < dailyCreatePostQuest.max_spins
      ) {
        await tx
          .insertInto("spins")
          .values([
            {
              quest_id: dailyCreatePostQuest.id,
              user_id: userId,
            },
          ])
          .execute();
      }

      await tx
        .insertInto("activities")
        .values({
          source_user_id: userId,
          record_name: "user",
          record_id: newPost.id,
          activity_type: "created_post",
          data: sql`cast(${JSON.stringify({
            id: newPost?.id,
            post_type: postType,
          })} as jsonb)`,
        })
        .onConflict((oc) => {
          return oc
            .columns([
              "source_user_id",
              "activity_type",
              "record_name",
              "record_id",
            ])
            .doNothing();
        })
        .execute();

      if (hashtags.length > 0 && tags) {
        const postTagValues = tags
          //@ts-ignore
          .filter((tag) => !tag.deleted_at)
          //@ts-ignore
          .map((tag) => ({
            post_id: newPost.id,
            tag_id: tag.id,
          }));

        await tx
          .insertInto("post_tags")
          .values(postTagValues)
          .onConflict((oc) => oc.columns(["post_id", "tag_id"]).doNothing())
          .execute();
      }

      if (mentions.length > 0) {
        const mentionValues = mentions.map((mention) => ({
          post_id: newPost.id,
          mention_id: mention.id,
          mention_type: mention.type,
          mention_text: mention.text,
        }));

        await tx.insertInto("mentions").values(mentionValues).execute();

        for (const mention of mentions) {
          let activityType;
          let sourceUserId = userId;
          switch (mention.type) {
            case "game":
              activityType = "mentioned_game";
              sourceUserId = userId;
              break;
            case "gamer":
              activityType = "mentioned_user";
              sourceUserId = mention.id;
              break;
            case "community":
              activityType = "mentioned_community";
              sourceUserId = userId;
              break;
          }

          if (activityType) {
            await tx
              .insertInto("activities")
              .values({
                source_user_id: sourceUserId,
                record_name: "mention",
                record_id: mention.id,
                activity_type: activityType,
                data: sql`cast(${JSON.stringify({
                  postId: newPost.id,
                  author: currentUser?.username,
                  mentionText: mention.text,
                  mentionId: mention.id,
                })} as jsonb)`,
              })
              .onConflict((oc) =>
                oc
                  .columns([
                    "source_user_id",
                    "activity_type",
                    "record_name",
                    "record_id",
                  ])
                  .doNothing(),
              )
              .execute();
          }
        }
      }

      return newPost.id;
    });
    // update user posts cache
    await fetch(
      `${process.env.EDGE_URL}/queue?action=update-user-posts&userId=${currentUser?.id}`,
    );
    return redirectOk(`/post/${postId}`, "Posted 🥳");
  } catch (error) {
    return redirectFail(request.url, "Something went wrong.");
  }
}

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const currentUser = await getCurrentUserFromSession(request);
  return {
    currentUser,
    originUrl: url.origin,
  };
}

const DEFAULT_ROW_HEIGHT = 720;
const OVERSCAN_ROW_COUNT = 2;

export default function Lobby() {
  const loaderData = useLoaderData<typeof loader>();
  const currentUser = loaderData.currentUser;
  useCurrentUser(currentUser);
  const listRef = React.useRef<List | null>(null);
  const [selectedPost, setSelectedPost] = React.useState<PostType | null>(null);
  const [selectedMediaIndex, setSelectedMediaIndex] = React.useState(0);
  const [showLatestUpdateBar] = useAtom(showLatestUpdateBarAtom);

  const { scrollingRef } = useOutletContext<{
    scrollingRef: React.RefObject<HTMLDivElement>;
  }>();
  const { ref: focusCarouselRef, inView: focusCarouselInView } = useInView({
    initialInView: true,
  });
  const [isCollapse] = useIsCollapse();

  const getPosts = async () => {
    try {
      const res = await fetch(`${window.ENV.EDGE_URL}/posts`);
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const { isLoading, data } = useQuery({
    queryKey: ["posts"],
    queryFn: getPosts,
  });
  const posts = data || [];

  const cache = new CellMeasurerCache({
    defaultHeight: DEFAULT_ROW_HEIGHT,
    fixedWidth: true,
  });

  const handleClickPostMedia = (post: PostType, mediaIndex: number) => {
    setSelectedPost(post);
    setSelectedMediaIndex(mediaIndex);
  };

  const postRowRenderer = ({ style, key, index, parent }: ListRowProps) => {
    const post = posts[index];

    if (!post) {
      return <PostSkeletonItem />;
    }

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnCount={1}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild, measure }) => (
          <div
            style={style}
            className="pb-4"
            ref={(ref) => {
              const el = ref as Element;
              return el && registerChild && registerChild(el);
            }}
            onLoad={measure}
          >
            <PostItem
              post={post}
              onClickMedia={(mediaIndex) =>
                handleClickPostMedia(post, mediaIndex)
              }
              onDeletePostSuccess={() => {
                cache.clear(index, 0);
                listRef?.current?.recomputeRowHeights(index);
              }}
              onDeleteCommentSuccess={() => {
                console.log("hello");
                cache.clear(index, 0);
                listRef?.current?.recomputeRowHeights(index);
              }}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      <div ref={focusCarouselRef}>
        <FocusCarousel />
      </div>
      <div className="flex flex-col lg:items-start lg:flex-row px-6 h-full">
        <div
          className={cn(
            "flex flex-col gap-6 pt-6 xl:pr-6 w-full xl:w-[calc(100%-360px)] transition-width duration-150 ease-in-out",
            {
              "xl:w-[calc(100%-350px)]": isCollapse,
            },
          )}
        >
          <CreatePost />

          {isLoading && <PostSkeletonItem />}

          <ClientOnly>
            {() =>
              scrollingRef?.current ? (
                <WindowScroller scrollElement={scrollingRef.current}>
                  {({ height, isScrolling, onChildScroll, scrollTop }) => (
                    <AutoSizer disableHeight>
                      {({ width }) => (
                        <List
                          autoHeight
                          height={height || DEFAULT_ROW_HEIGHT}
                          isScrolling={isScrolling}
                          onScroll={onChildScroll}
                          overscanRowCount={OVERSCAN_ROW_COUNT}
                          rowCount={posts.length}
                          rowHeight={cache.rowHeight}
                          rowRenderer={postRowRenderer}
                          ref={listRef}
                          scrollTop={scrollTop}
                          width={width}
                          tabIndex={null}
                        />
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              ) : null
            }
          </ClientOnly>
          {selectedPost && (
            <PostModal
              open={Boolean(selectedPost)}
              post={selectedPost}
              mediaIndex={selectedMediaIndex}
              onClose={() => setSelectedPost(null)}
              onDeleteSuccess={() => {
                setSelectedPost(null);
              }}
            />
          )}
        </div>
        <div className="h-full">
          <RightSidebar
            className={cn({
              "fixed top-[80px] h-[calc(100%-80px)]": !focusCarouselInView,
              "fixed top-[124px] h-[calc(100%-124px)]":
                !focusCarouselInView && showLatestUpdateBar,
            })}
          />
        </div>
      </div>
    </>
  );
}

interface PostItemProps {
  post: PostType;
  onClickMedia: (mediaIndex: number) => void;
  onDeletePostSuccess: () => void;
  onDeleteCommentSuccess: () => void;
}

export function PostItem(props: PostItemProps) {
  const [currentUser] = useCurrentUser();
  const { post, onClickMedia, onDeletePostSuccess, onDeleteCommentSuccess } =
    props;

  const getIndividualPost = async (): Promise<{
    stickers?: PostSticker[];
    comments?: Comment[];
  }> => {
    try {
      const res = await fetch(`${window.ENV.EDGE_URL}/posts/${post.id}`);
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["posts", post.id],
    queryFn: getIndividualPost,
  });

  const stickersCount = data?.stickers?.length || 0;
  const commentsCount = data?.comments?.length || 0;

  return (
    <Post key={post.id}>
      {post.media_urls.length !== 0 && (
        <PostStickers
          postId={post.id}
          stickers={data?.stickers || []}
          isLoading={isLoading}
        >
          <PostSlapBox postId={post.id} />
          <MediaCarousel
            mediaUrls={post.media_urls}
            onClick={(mediaIndex) => onClickMedia(mediaIndex)}
          />
        </PostStickers>
      )}
      <PostContent>
        <PostInfo>
          <PostUser
            avatarUrl={post.user_avatar_url || "/default-avatar.svg"}
            username={post.user_username}
          />
          <PostMenu
            createdAt={new Date(post.created_at).toISOString()}
            stickerCount={stickersCount}
          >
            <PostMenuPopover>
              <PostMenuPopoverItem asChild>
                <Link to={`/post/${post.id}`}>
                  <Eye /> View Post
                </Link>
              </PostMenuPopoverItem>

              {currentUser?.id &&
                (post.user_id === currentUser?.id ? (
                  <>
                    <EditPostPopoverItem
                      postId={post.id}
                      postContent={post.content}
                    />
                    <DeletePostPopoverItem
                      postId={post.id}
                      navigateTo="/"
                      onDeleteSuccess={onDeletePostSuccess}
                    />
                  </>
                ) : (
                  <TipPostPopoverItem
                    postId={post.id}
                    postOwnerId={post.user_id}
                  />
                ))}
            </PostMenuPopover>
          </PostMenu>
        </PostInfo>
        {post.content && <PostBody contentBody={post.content} />}
        {currentUser && (
          <PostAvailableStickers postId={post.id} ownerId={post.user_id} />
        )}
      </PostContent>
      <PostComment
        postId={post.id}
        commentCount={commentsCount}
        onDeleteSuccess={onDeleteCommentSuccess}
      />
    </Post>
  );
}

export function PostSkeletonItem() {
  return (
    <div className="pb-4">
      <div className="border border-primary rounded-2xl overflow-hidden relative">
        <div className="absolute z-10 right-6 top-5">
          <div className="flex items-center gap-2 border-2 bg-white border-primary-400 rounded-full pl-2 p-px animate-pulse">
            <div className="flex items-center gap-1">
              <div className="h-5 w-5 bg-gray-300 animate-pulse rounded-full" />
              <div className="sm:flex hidden bg-gray-300 animate-pulse h-3 w-20 rounded" />
            </div>
            <div className="flex items-center bg-primary-200 border-2 border-primary-400 rounded-full p-1 gap-1.5">
              <div className="h-5 w-5 bg-gray-300 animate-pulse rounded-full" />
              <div className="bg-gray-300 animate-pulse h-3 w-5 rounded" />
            </div>
          </div>
        </div>
        <div className="flex relative  aspect-square w-full sm:max-h-[600px] bg-black">
          <div className="absolute top-0 left-0 w-full h-full">
            <div className="flex h-full w-full">
              <div className="w-full h-full relative bg-black">
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="h-full w-full bg-center flex-1 bg-gray-300 animate-pulse" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6 px-4 flex flex-col gap-4 bg-white">
          <div className="flex items-center justify-between">
            <div className="rounded-full h-7 w-7 bg-gray-300 animate-pulse" />
            <div className="flex items-center gap-3 sm:gap-2">
              <div className="lg:flex hidden bg-gray-300 animate-pulse h-3 w-20 rounded" />
              <span className="bg-gray-300 animate-pulse h-3 w-20 rounded" />
              <div className="w-8 h-6 rounded-lg bg-gray-300 animate-pulse" />
            </div>
          </div>
          <div className="bg-gray-300 animate-pulse h-12 rounded" />
          <div className="border-t border-primary mt-1 pt-4 flex items-center gap-[10px] flex-wrap overflow-hidden">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="w-8 h-8 aspect-square bg-gray-300 animate-pulse rounded-md"
              />
            ))}
            <div className="w-8 h-8 bg-pink-300 rounded-full" />
          </div>
        </div>
        <div className="bg-[rgba(199,231,255,0.4)] p-4">
          <div className="flex gap-2">
            <div className="w-6 h-6 rounded-full bg-gray-300 animate-pulse" />
            <div className="bg-white flex-1 p-2 rounded-xl">
              <div className="flex gap-1">
                <div className="bg-gray-300 animate-pulse h-3 w-20 rounded" />
                <div className="bg-gray-300 animate-pulse h-3 w-20 rounded" />
              </div>
              <div className="pt-1">
                <div className="flex-1 bg-gray-300 animate-pulse h-6 rounded" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
