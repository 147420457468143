import { Button } from "@ariakit/react/button";
import { Link } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import { Scrollbars } from "react-custom-scrollbars";
import * as config from "~/config";
import { useCurrentUser } from "~/hooks/use-current-user";
import {
  type CompletedQuests,
  type FocusCarouselItem,
  FocusCarouselResourceType,
  type Sticker,
  type UserQuest,
} from "~/types";
import { cn } from "~/util/css";

const getFocusCarousel = async (): Promise<FocusCarouselItem[]> => {
  try {
    const res = await fetch(`${window.ENV.EDGE_URL}/focus-carousel`);
    const data = await res.json();
    return data || [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getUserQuests = async (userId: string): Promise<UserQuest[]> => {
  try {
    const res = await fetch(
      `${window.ENV.EDGE_URL}/user-quests?userId=${userId}`,
    );
    const data = await res.json();
    return data.userQuests || [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export function FocusCarousel() {
  const [currentUser] = useCurrentUser();

  const focusCarouselQuery = useQuery({
    queryKey: ["focus-carousel"],
    queryFn: getFocusCarousel,
  });

  const userQuestsQuery = useQuery({
    queryKey: ["user-quests", currentUser?.id],
    queryFn: () => getUserQuests(currentUser?.id as string),
    enabled: Boolean(currentUser?.id),
  });

  const userQuests = userQuestsQuery.data || [];

  const userQuestsByQuestId = userQuests.reduce(
    (acc, curr) => {
      acc[curr.quest_id] = curr;
      return acc;
    },
    {} as Record<number, UserQuest>,
  );

  const items = (focusCarouselQuery.data || [])
    .map((item) => {
      if (item.resource_type === "quests") {
        const userQuest = userQuestsByQuestId[item.resource_id];
        const isDailyQuest = userQuest?.category === "Daily";
        const meetsDailyLimit =
          userQuest?.points >= config.dailyPointEarnedLimit[item.data.slug];
        const completedAt =
          !isDailyQuest || meetsDailyLimit ? userQuest?.created_at : "";
        return {
          ...item,
          data: { ...item.data, completedAt },
        };
      }
      return item;
    })
    .filter((item) => {
      if (item.resource_type === FocusCarouselResourceType.QUESTS) {
        return !userQuestsByQuestId[item.resource_id];
      }
      return true;
    });

  if (focusCarouselQuery.isLoading || userQuestsQuery.isLoading) {
    return (
      <div className="flex flex-col gap-4 bg-[rgba(255,255,255,0.50)] pl-4 pt-2">
        <h2 className="font-medium text-[#6637CE]">In Focus</h2>
        <Scrollbars autoHeight autoHide universal>
          <div className="flex gap-4 flex-nowrap w-full pb-4">
            {Array(12)
              .fill(1)
              .map((n, i) => (
                <div
                  key={n + i}
                  className="relative w-[135px] h-[180px] flex-shrink-0 bg-gray-200 animate-pulse rounded-lg"
                />
              ))}
          </div>
        </Scrollbars>
      </div>
    );
  }

  return (
    <>
      {items.length > 0 && (
        <div className="flex flex-col gap-4 bg-[rgba(255,255,255,0.50)] pl-4 pt-2">
          <h2 className="font-medium text-[#6637CE]">In Focus</h2>
          <Scrollbars autoHeight autoHide universal>
            <div className="flex gap-4 flex-nowrap w-full pb-4">
              {items.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="relative w-[135px] h-[180px] flex-shrink-0 "
                  >
                    {item.resource_type === "quests" && (
                      <QuestCard quest={item.data} />
                    )}
                    {item.resource_type === "stickers" && (
                      <StickerCard sticker={item.data} key={item.id} />
                    )}
                  </div>
                );
              })}
            </div>
          </Scrollbars>
        </div>
      )}
    </>
  );
}

function QuestCard(props: { quest: CompletedQuests }) {
  const { quest } = props;
  return (
    <div
      className={cn(
        "w-full h-full rounded-lg relative overflow-hidden flex-shrink-0 group border border-white",
      )}
    >
      <Badge text="Quest" className="absolute top-1.5 right-1.5 z-10" />
      <div
        className="h-full relative bg-cover bg-center"
        style={{
          backgroundImage: `url(${quest.image_url})`,
        }}
      >
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            background:
              "linear-gradient(180deg, rgba(144, 98, 255, 0) 43.89%, rgba(164, 71, 236, 0.22) 58.59%, #833AE5 90.56%), linear-gradient(180deg, rgba(67, 39, 163, 0.33) 0%, rgba(34, 41, 211, 0.43) 100%)",
          }}
        >
          <div className="absolute bottom-0 left-0 w-full">
            <div className="flex p-2 gap-1">
              <p className="flex-1 text-white text-xs font-semibold line-clamp-3">
                {quest.name}
              </p>
              {quest.slug.startsWith("follow_x") && (
                <img
                  src="/icons/quest-x.svg"
                  alt="quest-x"
                  className="w-6 h-6 self-end"
                />
              )}
              {quest.slug.startsWith("join_discord") && (
                <img
                  src="/icons/quest-discord.svg"
                  alt="quest-discord"
                  className="w-6 h-6 self-end"
                />
              )}
              {quest.slug.startsWith("follow_twitch") && (
                <img
                  src="/icons/quest-twitch.svg"
                  alt="quest-discord"
                  className="w-6 h-6 self-end"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={cn(
          "bg-[linear-gradient(84deg,#9E78FF_0%,#989DFF_124.78%)] hidden text-center group-hover:block absolute top-0 left-0 w-full h-full z-10",
          {
            "bg-[linear-gradient(84deg,#B0FFEA_0%,#93ECFF_124.78%)]":
              quest.completedAt,
          },
        )}
      >
        <Link
          to={`/quests/${quest.slug}`}
          className="flex flex-col gap-4 items-center justify-center h-full p-2"
        >
          {quest.completedAt ? (
            <>
              <div>
                <p className="text-sm font-medium mb-1 line-clamp-3">
                  {quest.name}
                </p>
                <div className="flex justify-center items-center gap-1 text-xs">
                  <img
                    className="w-[13px] h-[13px]"
                    src="/icons/check_circle.svg"
                    alt="check"
                  />
                  COMPLETED
                </div>
              </div>
              <Button className="bg-white rounded-full p-[10px] text-xs font-medium">
                Go to quest
              </Button>
            </>
          ) : (
            <>
              <div>
                <p className="text-sm font-medium mb-1 line-clamp-3">
                  {quest.name}
                </p>
              </div>
              <Button className="bg-white rounded-full p-[10px] text-xs font-medium">
                Go to quest
              </Button>
            </>
          )}
        </Link>
      </div>
    </div>
  );
}

function StickerCard(props: { sticker: Sticker }) {
  const sticker = props.sticker;
  return (
    <div
      className="w-full h-full rounded-lg overflow-hidden group border border-white "
      style={{
        backgroundImage:
          "linear-gradient(122deg,rgba(255,164,220,0.37) 16.13%, rgba(107,104,255,0.30) 98.86%)",
      }}
    >
      <Link to={`/sticker/${sticker.id}`}>
        <div
          className="w-full h-full bg-contain bg-center bg-no-repeat relative"
          style={{ backgroundImage: `url(${sticker.image_url})` }}
        >
          <Badge text="Sticker" className="absolute top-1.5 right-1.5" />
          <div className="flex flex-col gap-1 absolute bottom-0 left-0 w-full p-2 bg-[rgba(250,197,255,0.75)] border-t border-[#FFD9F0]">
            <p className="text-xs font-medium line-clamp-1">
              {sticker.sticker_pack_name}
            </p>
            <div className="flex items-center gap-1">
              <img
                className="w-4 h-4 rounded-full border border-white"
                src={sticker.owner_avatar_url}
                alt=""
              />
              <p className="text-xs line-clamp-1">
                {sticker.owner_username === "admin"
                  ? "Soulbound"
                  : sticker.owner_username}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

interface BadgeProps {
  className: string;
  text: string;
}

function Badge(props: BadgeProps) {
  return (
    <div
      className={cn(
        "text-xs font-medium border border-black rounded-full px-1 py-0.5 uppercase bg-white shadow-[0px_2px_0px_0px_#000]",
        props.className,
      )}
    >
      {props.text}
    </div>
  );
}
