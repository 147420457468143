import type {
  AdminshipRecordType,
  BetStatus,
  RewardTokenType,
  SanityCategory,
} from "./enums";

export type Sticker = {
  id: string;
  name: string;
  imageUrl: string;
  hasStore: boolean;
  createdBy: string;
  price: string;
  image_url: string;
  created_at: string;
  sticker_pack_name?: string;
  owner_username?: string;
  owner_avatar_url?: string;
};

export interface PostSticker {
  post_id: string;
  sticker_id: string;
  created_at?: string;
  position_x: number;
  position_y: number;
  owner_id?: string;
  rotation_angle: number;
  image_url: string;
  owner_username?: string;
  owner_avatar_url?: string;
}

export type SlappedSticker = PostSticker | null;

interface Adminships {
  id: string;
  recordType: AdminshipRecordType;
  data: string;
}

export interface LoggedInUser {
  id: string;
  externalId: string;
  email: string;
  username: string;
  avatarUrl?: string;
  coverUrl?: string;
  referralCode?: string;
  adminships?: Adminships[];
}

export type CurrentUser = LoggedInUser | null;

export interface UserSlappedSticker {
  postId: string;
  stickerId: string;
  imageUrl: string;
}

export type CurrentSlappedSticker = SlappedSticker | null;

export interface Collector {
  id: string;
  username?: string;
  avatar_url?: string;
}

export interface Post {
  tags: any;
  id: string;
  content: string;
  created_at: string;
  media_urls: string[];
  stickers: PostSticker[];
  comment_count: number;
  sticker_count: number;
  post_type: string;
  user_id: string;
  user_username: string;
  user_avatar_url: string;
}

export type ActivityType =
  | "new_signup"
  | "received_drip"
  | "claimed_drip"
  | "posted_comment"
  | "followed_user"
  | "followed_game"
  | "joined_community"
  | "post_slapped"
  | "tip_sent"
  | "new_game"
  | "new_quest"
  | "slapped_sticker"
  | "sticker_sold"
  | "received_comment"
  | "referral_signup"
  | "quest_submission_approved"
  | "quest_submission_rejected"
  | "community_membership_approved"
  | "community_membership_rejected"
  | "community_membership_revoked"
  | "claimed_sticker_pack_reward"
  | "mentioned_user"
  | "mentioned_game"
  | "mentioned_community"
  | "comment_mention"
  | "streamer_now_streaming"
  | "made_a_bet";
type RecordName = "users" | "user_quests";

export interface Activity extends Record<string, string | number | boolean> {
  id: number;
  activity_type: ActivityType;
  record_name: RecordName;
  data: string;
  created_at: string;
  read: boolean;
  source_user_id: string;
  updated_at: string;
}

export interface HappeningNowActivity
  extends Omit<Activity, "data" | "read" | "record_name"> {
  username: string;
  user_avatar_url: string;
  data: any;
  id: number;
}

export interface Collection {
  id: number;
  name: string;
  created_at: string;
  owner_id: number;
  slug: string;
}

export interface TrendingPostsActivity {
  sticker_count: string;
  username: string;
  avatar_url: string;
  post_id: number;
  comment_count: number;
  total_count: number;
  media_urls: string[];
}

export interface TrendingStickersActivity {
  owner_username: string;
  owner_avatar_url: string;
  image_url: string;
  id: string;
}

export type StickerTransaction = {
  id: string;
  sticker_id: string;
  from_username: string;
  price_amount: number;
  price_currency: string;
  sticker_image_url: string;
  tx_hash: string;
  created_at: string;
  explorer_url: string;
};
export interface Comment {
  id: number;
  created_at: string;
  content: string;
  owner_username: string;
  owner_avatar_url: string;
}

export type MerkleClaims = {
  [key: string]: {
    index: number;
    amount: string;
    proof: string[];
  };
};

export interface ChainAddresses {
  [chainId: number]: {
    soulboundAccountAddress?: `0x${string}`;
    popTokenAddress?: `0x${string}`;
    airdropDistributorAddress?: `0x${string}`;
    buyLink?: string;
    sellLink?: string;
  };
}
export interface Wallet {
  address: string;
}
export interface Tag {
  id: number;
  name: string;
  deleted_at?: string;
}

export interface Chain {
  chain_id: number;
  name: string;
  explorer_url: string;
}

export interface Quest {
  id: number;
  name: string;
  slug: string;
  image_url: string;
  description?: string;
  start_at: string;
  end_at: string;
  max_points: number;
  participant_type: string;
  game_name?: string;
  game_image_url?: string;
  community_name?: string;
  community_logo_url?: string;
  community_id?: string;
  quest_pack_id?: string;
  game_id: string;
  completed_count: string;
}

export interface UserQuest {
  id: number;
  user_id: number;
  quest_id: number;
  claimed_at: string;
  created_at: string;
  category?: string;
  status?: string;
  points?: number;
  slug?: string;
}

export interface CompletedQuests {
  name: string;
  completedAt: string;
  claimedAt: string;
  imageUrl: string;
  slug: string;
}

export interface CompletedUserQuests {
  id: string;
  username: string;
  avatar_url: string;
}

export interface StickerPack {
  id: string;
  name: string;
  slug?: string;
}

export interface GamePlatform {
  id: string;
  name: string;
}

export interface Game {
  id: number;
  name: string;
  slug: string;
  image_url: string;
  video_url: string;
  status?: string;
  genre?: string;
  platforms?: GamePlatform[];
  network?: string;
}

export interface Community {
  id: number;
  name: string;
  slug: string;
  description: string;
  logo_url: string;
}

export interface FocusCarouselItem {
  id: number;
  resource_type: string;
  resource_id: string;
  data: Sticker | Quest;
}

export interface Bounty {
  id: number;
  name: string;
  image_url: string;
  description?: string;
  start_at: string;
  end_at: string;
  max_points: number;
  game_image_url?: string;
  game_name?: string;
}

export interface CloudflareAvatar {
  cloudflare_avatar_url?: string;
  cloudflare_asset_processed_at?: string;
}

export interface User extends CloudflareAvatar {
  id: string;
  username: string;
  avatar_url: string;
  cover_url: string;
}

export interface CommunityMember {
  id: string;
  username: string;
  avatarUrl: string;
  approvedAt: string;
  rejectedAt: string;
  revokedAt: string;
}

export type UserRole = {
  name: string;
};

export interface QuestSubmission {
  id: string;
  createdAt: string;
  userUsername: string;
  userAvatarUrl: string;
  data: string;
  questSlug: string;
}

export interface StreamerRegistration {
  id: number;
  user_id: string;
  status: string;
  created_at: string;
  profile_url?: string;
}

export interface RewardToken {
  id: number;
  name: string;
  type: RewardTokenType;
  address: string;
  chain_id: number;
  token_id: number;
}

export interface Rewards {
  sticker_pack_id?: number;
  reward_tokens_id?: number;
}
export type RewardDetails = RewardToken & Omit<Rewards, "reward_tokens_id">;

export interface QuestReward {
  id: number;
  quest_id: number;
  reward_type: string;
  distribution_strategy: string;
  name: string;
  description: string;
  image_url: string;
  redemption_limit: number;
  rewards_id?: number;
  token_amount?: number;
  reward_details?: RewardDetails;
}

export interface GameFollower {
  id: string;
  username: string;
  avatarUrl: string;
  createdAt: string;
}
export interface Mention {
  id: number;
  text: string;
  slug: string;
}

export interface Stream {
  id: string;
  user_id: string;
  user_login: string;
  user_name: string;
  game_id: string;
  game_name: string;
  type: string;
  title: string;
  viewer_count: number;
  started_at: string;
  language: string;
  thumbnail_url: string;
  tag_ids: Array<string>;
  tags: Array<string>;
  is_mature: boolean;
}

export type StreamerDetails = {
  id: number;
  email: string;
  name: string;
  twitch_url: string | null;
  telegram_url: string | null;
  discord_url: string | null;
  website_url: string | null;
  created_at: string;
};

export type QuestPack = {
  id: number;
  name: string;
  image_url: string;
  description: string;
  start_at: string;
  end_at: string;
};

export enum Settings {
  LATEST = "latest",
}

export enum CacheKey {
  QUESTS = "quests",
}

declare global {
  interface Window {
    ENV: Record<string, any>;
  }
}

export enum FocusCarouselResourceType {
  QUESTS = "quests",
  STICKERS = "stickers",
}

export interface TrendingPost {
  post_id: string;
  comment_count: string;
  sticker_count: string;
  total_count: string;
  media_urls: string[];
  username: string;
  avatar_url: string;
}

export type Paragraph = {
  _key?: string;
  _type?: string;
  text?: string;
  children?: ParagraphItem[];
  listItem?: string;
};

export type ParagraphItem = {
  _key?: string;
  _type?: string;
  text?: string;
  marks?: string[];
};

export type Section = {
  _id?: string;
  title?: string;
  subheading?: string;
  table?: any[];
  images?: string[];
  paragraphs?: Paragraph[];
};

export type Article = {
  _id?: string;
  title?: string;
  sections?: Section[];
};

export type SanitySearchKey = {
  slugSearch?: string | null;
  titleSearch?: string | null;
  categorySearch?: SanityCategory | null;
};

export interface BetOption {
  bet_option_id: string;
  description: string;
  count?: number;
  percentage?: number;
}

export type Bettor = {
  avatar_url: string;
  username: string;
  id: string;
};

export type Prediction = {
  avatar_url?: string;
  username?: string;
  question_id: number;
  title: string;
  description: string;
  status: BetStatus;
  stream_url: string;
  start_at: string;
  resolution_datetime: string;
  options: { id: string; description: string }[];
  winning_bet_option_id?: number;
  thumbnailURL: string;
};

export interface UserBet {
  bet_question_id: number;
  bet_option_id: number;
  amount: number;
  description: string;
  currency?: string;
}
export interface GameNetwork {
  id: string;
  name: string;
}

export interface GameGenres {
  id: string;
  name: string;
}
